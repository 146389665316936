// Colors
$primary: #5BB956;
$secondary: #787878;
$text-light: white;
$text-dark: #787878;
$text-darker: #5b5a5a;
$tile-bg: #88c8f6;
$form-text: #c1c1c1;
$process-bg: #8EC9F8;
$admin-badge: #b92332;

// Spacing
$mobileGutter: 1.25rem;
$spacingParagraph: 2.5rem;

$paragraph-spacer: 3.5rem;
$block-spacer: 6rem;
$block-spacer-mobile: 5rem;
$paragraph-spacer-mobile: 3rem;

// Breakpoints
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
