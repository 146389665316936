@import "./includes/settings.scss";

:root {
    font-size: 16px;
    font-family: "Montserrat";
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}

body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    li,
    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: unset;
        margin: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        margin-left: 5rem;
        flex: 1 1 100%;
    }

    .container {
        display: flex;
        flex: 1 1 100%;
    }
    .page {
        height: 100vh;
        align-content: flex-start;
    }
    .flex-column {
        flex-direction: column;
    }
    .bold {
        font-weight: 900;
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
    background: $primary;
}

.text-muted {
    color: #6c757d !important;
}

// Large screen limit
@media only screen and (min-device-width: 2100px) {
    .clamp {
        max-width: 1920px;
    }
}

.mobile-ignore {
    // Phone
    @media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
        display: none;
    }
}

.mobile-only {
    display: none;
    // Phone
    @media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
        display: inline-flex;
    }
}

// Button Styles
.btn-primary {
    background-color: $primary;
    color: $text-light;
    border-color: $primary;
    font-family: "Roboto";
    font-size: 1rem;
    font-weight: 500;
    line-height: 18.75px;
    border-radius: 30px;

    width: 150px;
    padding: 0.75rem 1rem;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
        background-color: $text-light;
        border-color: $text-light;
        color: $primary;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
    }

    &:disabled {
        filter: grayscale(100%) opacity(0.7);
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}
.inverted {
    background-color: $text-light;
    color: $text-dark;

    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($text-dark, 0.2);
    }
}

.btn-link {
    background-color: transparent;
    font-family: "Montserrat";
    border: unset;
    color: $text-dark;
    font-weight: 700;
    text-decoration: underline;
}

// Fonts
@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto-Medium";
    src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Headers and Text
.h1-heading {
    color: $text-dark;
    font-size: 36px;
    font-family: "Roboto";
    font-weight: normal;
    line-height: 42px;
}

.h2-heading {
    color: $text-dark;
    font-size: 36px;
    font-family: "Roboto";
    font-weight: normal;
    line-height: 42px;
}

.h3-heading {
    color: $text-dark;
    font-size: 24px;
    font-family: "Roboto-Medium";
    font-weight: 500;
    line-height: 28px;
}

.h4-heading {
    color: $text-dark;
    font-size: 24px;
    font-family: "Roboto";
    font-weight: normal;
    line-height: 28px;
}

.h5-heading {
    color: $text-dark;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: normal;
    line-height: 21px;
}

.p-text {
    color: $text-dark;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
}

.list-container {
    ul {
        list-style-image: url("../assets/icons/tick-green.svg");

        li {
            padding: 1rem;
        }
    }
}
.alt {
    ul {
        list-style-image: url("../assets/icons/tick-white.svg");
    }
}
